import React from "react";

import communications from "./images/Anuj-img-3.png";

function Contact() {
  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper">
          <h1>Contact</h1>
          {/* <p>
            Get ready to heighten your communication and anchoring skills with
            upcoming workshops. Anuj is excited to share his expertise through
            interactive, live sessions designed for students and adults.
          </p> */}

          <div className="communications-coach-wrap">
            <div className="communications-coach-1">
              <div className="communications-coach-1-1">
                <div className="communications-box">
                  <b>YOUR NAME</b>
                  <input type="text" placeholder="" />
                </div>
                <div className="communications-box">
                  <b>EMAIL</b>
                  <input type="text" placeholder="" />
                </div>
                <div className="communications-box">
                  <b>PHONE</b>
                  <input type="text" placeholder="" />
                </div>
                <div className="communications-box">
                  <b>MESSAGE</b>
                  <input type="text" placeholder="" />
                </div>
                <div className="communications-box">
                  <button>SUBMIT</button>
                </div>
              </div>
            </div>
            <div className="communications-coach-2">
              <img src={communications} alt="communications" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
