import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import trustbyimgs from "./trustedby";

function TrustedScroll() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 9,
    autoPlay: true,
    arrows: true,
    autoplaySpeed: 100,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {trustbyimgs.map((imgnme, index) => (
          <div key={index}>
            <div className="carousel-img">
              <img src={imgnme.src} alt={imgnme.alt} />
            </div>
          </div>
        ))}
        {/* Optionally, duplicate images for a seamless effect */}
        {trustbyimgs.map((imgnme, index) => (
          <div key={index + imgnme.length}>
            <div className="carousel-img">
              <img src={imgnme.src} alt={imgnme.alt} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TrustedScroll;
